import { datadogRum } from '@datadog/browser-rum'
import { IFreightieJSON } from 'models/Freightie'
import { IEnv } from 'util/env'

import { getEndpointRegistry, getEndpointsFromConfig } from './EndpointRegistry'

export interface DatadogServiceProps {
  appId: string
  clientToken: string
  service: string
  site: string
  environment: string
  version: string
  isEnabled: boolean
  isSessionReplayEnabled: boolean
  allowedTracingUrls: string[]
}

export class DatadogService {
  static start(env: IEnv): DatadogService | undefined {
    if (!window.datadogService) {
      try {
        window.datadogService = new DatadogService({
          appId: env.DATADOG_APP_ID,
          clientToken: env.DATADOG_CLIENT_TOKEN,
          isEnabled: env.DATADOG_RUM_ENABLED,
          isSessionReplayEnabled: env.DATADOG_RUM_SESSION_REPLAY_ENABLED,
          service: env.DATADOG_RUM_SERVICE,
          site: env.DATADOG_RUM_SITE,
          environment: env.APP_ENV || env.NODE_ENV,
          version: env.COMMIT,
          allowedTracingUrls: [
            window.location.origin,
            ...getEndpointsFromConfig(getEndpointRegistry(env)),
            env.SHIPPING_ENDPOINT,
            env.WEBSOCKET_ENDPOINT,
            env.SUBSCRIPTIONS_ENDPOINT,
            env.RATES_MANAGEMENT_ENDPOINT,
            env.QUOTATION_ENDPOINT,
          ],
        })
      } catch (e) {
        console.error('Datadog Initialization failed to start before authentication. Error: ', e)
      }
    }

    return window.datadogService
  }

  private appId: string
  private clientToken: string
  private service: string
  private site: string
  private environment: string
  private version: string
  private isEnabled: boolean
  private isSessionReplayEnabled: boolean
  private allowedTracingUrls: string[]

  constructor({
    appId,
    clientToken,
    service,
    allowedTracingUrls,
    site,
    environment,
    version,
    isEnabled,
    isSessionReplayEnabled,
  }: DatadogServiceProps) {
    this.appId = appId
    this.clientToken = clientToken
    this.service = service
    this.site = site
    this.environment = environment
    this.version = version
    this.isEnabled = isEnabled
    this.isSessionReplayEnabled = isSessionReplayEnabled
    this.allowedTracingUrls = allowedTracingUrls

    if (this.isEnabled) {
      try {
        // Always initialise datadog, so we have monitoring in place even if the
        // login does not work.
        datadogRum.init({
          applicationId: this.appId,
          clientToken: this.clientToken,
          site: this.site,
          service: this.service,
          env: this.environment,
          version: this.version,
          sessionSampleRate: 100,
          sessionReplaySampleRate: this.isSessionReplayEnabled ? 100 : 0,
          silentMultipleInit: true,
          defaultPrivacyLevel: 'allow',
          trackResources: true,
          trackUserInteractions: true,
          allowedTracingUrls: this.allowedTracingUrls,
        })
        console.log(`Datadog Session ${this.getSessionId()} Initialized`)
      } catch (e) {
        console.error('Datadog Initialization failed in constructor. Error: ', e)
      }
    }
  }

  public setUser(freightie: IFreightieJSON): void {
    if (this.isEnabled) {
      try {
        datadogRum.setUser({
          id: freightie._id,
          name: freightie.name,
          email: freightie.email,
          roles: freightie.roles,
        })
      } catch (e) {
        console.error('Datadog Setting user failed. Error: ', e)
      }
    }
  }

  public getSessionId(): string | undefined {
    return this.isEnabled ? datadogRum.getInternalContext()?.session_id || undefined : undefined
  }

  public getSessionReplayUrl(): string | undefined {
    return this.isEnabled ? datadogRum.getSessionReplayLink() : undefined
  }
}
